<template>
    <div>
        <div class="sm:flex justify-between">
            <div class="font-bold text-primary text-xl">Address List</div>
            <button
                @click="$router.push({ name: 'AccountAddressAdd' })"
                class="flex items-center bg-gray-200 hover:bg-gray-300 py-1 px-3 rounded-xl"
            >
                <font-awesome-icon class="mr-2" icon="fa-regular fa-circle-xmark" :transform="{ rotate: 45 }" />
                <div class="font-semibold text-sm">Add Address</div>
            </button>
        </div>
        <not-found-card v-if="addresses.length == 0" class="mt-8"></not-found-card>
        <table v-else class="table-auto w-full mt-8">
            <thead class="text-primary">
                <tr>
                    <th class="text-left text-x sm:text-base">Location</th>
                    <th class="text-left text-x sm:text-base">Name</th>
                    <th class="text-left text-x sm:text-base">Phone</th>
                    <th class="text-left text-x sm:text-base">Address</th>
                    <th class="text-x sm:text-base">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="address in addresses" :key="address.id_alamat_user" class="font-medium border-t-2 text-x sm:text-base">
                    <td>{{ address.nama_simpan }}</td>
                    <td>{{ address.nama }}</td>
                    <td>{{ address.telepon }}</td>
                    <td>{{ address.alamat_lengkap }}</td>
                    <td class="text-center py-3 text-x sm:text-base">
                        <font-awesome-icon
                            @click="setAsMain(address.id_alamat_user)"
                            class="cursor-pointer"
                            :class="{ 'text-primary': address.alamat_utama == 1 }"
                            icon="fa-solid fa-circle-check"
                        />
                        <font-awesome-icon
                            @click="openEditPage(address)"
                            class="cursor-pointer mx-4"
                            icon="fa-solid fa-pen-to-square"
                        />
                        <font-awesome-icon
                            @click="deleteAddress(address.id_alamat_user)"
                            class="cursor-pointer"
                            icon="fa-solid fa-trash"
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    export default {
        name: 'AccountAddressIndex',

        components: {
            NotFoundCard: () => import('@/components/layout/NotFoundCard.vue')
        },

        data() {
            return {
                addresses: []
            };
        },

        created() {
            this.$store.commit('setBreadcrumbItems', [
                { text: 'Home', routeName: 'Home' },
                { text: 'Account', routeName: 'AccountProfile' },
                { text: 'Account Setting', routeName: 'AccountProfile' },
                { text: 'My Address', routeName: 'AccountAddressIndex' }
            ]);

            this.getAddresses();
        },

        methods: {
            async getAddresses() {
                this.$store.commit('setOverlayLoading', true);

                const data = new FormData();
                data.append('txtId', this.$store.state.user.id_user);

                try {
                    const response = await this.$http.post(
                        `${this.$apiTripwe}/ic/jetski-android-new/list_alamat_pengiriman2.php`,
                        data
                    );

                    if (response.data === '') {
                        throw 'Get addresses failed';
                    }

                    this.addresses = response.data;
                } catch (error) {
                    this.$toasted.global.error(error);
                }

                this.$store.commit('setOverlayLoading', false);
            },
            async setAsMain(id) {
                this.$store.commit('setOverlayLoading', true);

                this.addresses
                    .filter(val => val.alamat_utama == 1)
                    .forEach(async address => {
                        const data = new FormData();
                        data.append('txtIdAlamatPengiriman', address.id_alamat_user);
                        data.append('txtAlamatUtama', 0);
                        data.append('mobile', 'android');

                        try {
                            const response = await this.$http.post(
                                `${this.$apiTripwe}/ic/jetski-android-new/update_alamat_utama_dv15.php`,
                                data
                            );

                            if (response.data != 'success') {
                                throw 'Update main address failed';
                            }
                        } catch (error) {
                            this.$toasted.global.error(error);
                        }
                    });

                const data = new FormData();
                data.append('txtIdAlamatPengiriman', id);
                data.append('txtAlamatUtama', 1);
                data.append('mobile', 'android');

                try {
                    const response = await this.$http.post(
                        `${this.$apiTripwe}/ic/jetski-android-new/update_alamat_utama_dv15.php`,
                        data
                    );

                    if (response.data != 'success') {
                        throw 'Update main address failed';
                    }

                    await this.getAddresses();
                } catch (error) {
                    this.$toasted.global.error(error);
                }

                this.$store.commit('setOverlayLoading', false);
            },
            openEditPage(address) {
                this.$router.push({ name: 'AccountAddressEdit', params: { address } });
            },
            async deleteAddress(id) {
                this.$store.commit('setOverlayLoading', true);

                const data = new FormData();
                data.append('txtId', id);
                data.append('mobile', 'android');

                try {
                    const response = await this.$http.post(
                        `${this.$apiTripwe}/ic/jetski-android-new/delete_alamat_pengiriman.php`,
                        data
                    );

                    if (response.data != 'success') {
                        throw 'Delete address failed';
                    }

                    await this.getAddresses();
                } catch (error) {
                    this.$toasted.global.error(error);
                }

                this.$store.commit('setOverlayLoading', false);
            }
        }
    };
</script>
